import { takeLatest, fork } from 'redux-saga/effects';
import loginSaga from './loginSaga';
import logoutSaga from './logoutSaga';
import createUserSaga from './createUserSaga';
import resendSaga from './resendSaga';
import setUserCurrentRoleSaga from './setUserCurrentRoleSaga';
import patchUserSaga from './patchUserSaga';
import editUserSaga from './editUserSaga';
import loadUserSaga from './loadUserSaga';
import loadUserUuidSaga from './loadUserUuidSaga';
import activateUserSaga from './activateUserSaga';
import blockUserSaga from './blockUserSaga';
import saveProfileSaga from './saveProfileSaga';
import loadProfileSaga from './loadProfileSaga';
import getUserInvitesSaga from './getUserInvitesSaga';
import { setUserUploadSaga, cancelUploadingUserFileSaga } from './setUserUploadSaga';
import deleteUserUploadSaga from './deleteUserUploadSaga';
import { TYPES } from '../constants';
import resetUserPasswordSaga from './resetUserPasswordSaga';
import changeUserPasswordSaga from './changeUserPasswordSaga';
import updateUserSettingsSaga from './updateSettingsSaga';
import updateUserPasswordSaga from './updateUserPasswordSaga';
import favoriteUserSaga from './favoriteUserSaga';
import verifyCodeSaga from './verifyCode';
import verifyUnionInfoSaga from './verifyUnionSaga';
import updateUserRoles from './updateUserRolesSaga';
import setUserStatusSaga from './setUserStatusSaga';
import verifyPasswordTokenSaga from './verifyPasswordTokenSaga';
import setDedicatedSaga from './setDedicatedSaga';

export default function* userSagas(): Generator {
  yield takeLatest(TYPES.LOGIN.INIT, loginSaga);
  yield takeLatest(TYPES.LOGOUT.INIT, logoutSaga);
  yield takeLatest(TYPES.VERIFY_CODE.INIT, verifyCodeSaga);
  yield takeLatest(TYPES.CREATE_USER.INIT, createUserSaga);
  yield takeLatest(TYPES.RESEND.INIT, resendSaga);
  yield takeLatest(TYPES.SET_USER_CURRENT_ROLE.INIT, setUserCurrentRoleSaga);
  yield takeLatest(TYPES.PATCH_USER.INIT, patchUserSaga);
  yield takeLatest(TYPES.EDIT_USER.INIT, editUserSaga);
  yield takeLatest(TYPES.LOAD_USER.INIT, loadUserSaga);
  yield takeLatest(TYPES.LOAD_USER_UUID.INIT, loadUserUuidSaga);
  yield takeLatest(TYPES.ACTIVATE_USER.INIT, activateUserSaga);
  yield takeLatest(TYPES.BLOCK_USER.INIT, blockUserSaga);
  yield takeLatest(TYPES.RESET_USER_PASSWORD.INIT, resetUserPasswordSaga);
  yield takeLatest(TYPES.VERIFY_PASSWORD_TOKEN.INIT, verifyPasswordTokenSaga);
  yield takeLatest(TYPES.CHANGE_USER_PASSWORD.INIT, changeUserPasswordSaga);
  yield takeLatest(TYPES.USER_UPDATE_PASSWORD.INIT, updateUserPasswordSaga);
  yield takeLatest(TYPES.USER_FAVORITE.INIT, favoriteUserSaga);

  yield takeLatest(TYPES.UPLOAD_USER_FILE.INIT, setUserUploadSaga);
  yield takeLatest(TYPES.DELETE_USER_FILE.INIT, deleteUserUploadSaga);
  yield fork(cancelUploadingUserFileSaga);

  yield takeLatest(TYPES.SAVE_PROFILE.INIT, saveProfileSaga);
  yield takeLatest(TYPES.LOAD_PROFILE.INIT, loadProfileSaga);
  yield takeLatest(TYPES.GET_USER_INVITES.INIT, getUserInvitesSaga);
  yield takeLatest(TYPES.UPDATE_USER_SETTINGS.INIT, updateUserSettingsSaga);
  yield takeLatest(TYPES.VERIFY_UNION_INFO.INIT, verifyUnionInfoSaga);
  yield takeLatest(TYPES.UPDATE_USER_ROLES.INIT, updateUserRoles);
  yield takeLatest(TYPES.SET_USER_STATUS.INIT, setUserStatusSaga);
  yield takeLatest(TYPES.SET_DEDICATED.INIT, setDedicatedSaga);
}
