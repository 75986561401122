export const validation = {
  zipCode: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  ssn: /^(\d{3})-?(\d{2})-?(\d{4})$/,
  phone: /^[0-9]+$/,
};

export const ssnMask = (value: string): string =>
  value
    .replace(/[^\d-]/g, '')
    .replace(/^(\d{3})-?(\d{1,2})/, '$1-$2')
    .replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, '$1-$2-$3');

export const zipCodeMask = (value: string): string => value.replace(/[^\d-]/g, '').replace(/^(\d{5})-?(\d{1,4})/, '$1-$2');

export const maskPhone = (value: string): string => {
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{3})(\d)/g, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
};
