import { ThemeOptions } from '@mui/material';
/**
 * Colors configuration
 */
export const themeColors = {
  black: '#000000',
  grayDark: '#575656',
  grayMedium: '#eaeaea',
  grayLight: '#f9f9f9',
  yellowMain: '#FF5000',
  yellow: '#f9db4e',
  lightYellow: '#fcefc9',
  grayDisabled: '#d9d9d9',
  grayPlaceholders: '#9f9e9e',
  green: '#4fa546',
  greenLight: '#def7e4',
  red: '#e0433e',
  redLight: '#fbeae9',
  redError: '#ffe9e9',
  blue: '#054FB9',
  blueLight: '#c8dcfa',
  white: '#ffffff',

  primary: '#000000',
  secondary: '#ed9108',
  backgroundColor: '#ffffff',
  success: '#4fa546',
  warning: '#f9db4e',
  error: '#e0433e',
  info: '#3478f6',
  transparent: 'transparent',
};

/**
 * Theme configuration
 */
export const theme = {
  palette: {
    primary: {
      main: themeColors.primary,
      hover: themeColors.primary,
      active: themeColors.primary,
      disabled: themeColors.primary,
    },
    background: {
      default: themeColors.white,
    },
    error: {
      main: themeColors.error,
    },
  },

  typography: {
    fontFamily: ['Poppins', 'Roboto', 'sans-serif'].join(','),
    // Technical Debt: Investigate it, because it's convert to ~3rem
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.3,

    // H1
    h1: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.3,
    },

    // H2
    h2: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: 1.3,
    },

    // H3
    h3: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.3,
    },

    // P1M, P1R
    h4: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.3,
    },

    // P2, P2M, P2R
    h5: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 2,
    },

    // P3
    h6: {
      fontSize: '0.625rem',
      fontWeight: 400,
      lineHeight: 2.4,
    },

    // Button
    button: {
      fontSize: '0.825rem',
      fontWeight: 500,
      lineHeight: 1.5,
      textAlign: 'center',
    },

    // Technical Debt: This is a temporary fix for Link with the typography
    // Link
    overline: {
      fontSize: '0.825rem',
      fontWeight: 400,
      lineHeight: 1.5,
      textTransform: 'initial',
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '10px',
        },
      },
    },
  },
} as ThemeOptions;
