import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { themeColors } from 'src/theme';
import { H3, FormikInputString, FormikInputSelect, FormikCalendar, FormikInputPhone } from 'src/components';
import config from 'src/config';
import { ListType, UserType } from 'src/types';
import { FormValues } from '../index';

type Props = {
  formik: FormikProps<FormValues>;
  ownerOptions: UserType[];
};

const RosterDetailsStep = ({ formik, ownerOptions }: Props) => {
  const { t } = useTranslation();
  const optionsSelect: ListType[] = ownerOptions.map((lineman) => ({
    key: lineman.uuid || '',
    value: `${lineman.firstName} ${lineman.lastName}`,
  }));

  return (
    <Grid
      container
      sx={{
        margin: '30px auto 80px',
        padding: '0 24px',
        flexGrow: 1,
        overflow: 'auto',
        position: 'relative',
      }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            width: { md: '622px' },
            padding: '24px 24px 1px 24px',
            margin: '0 auto 24px',
            borderRadius: '4px',
            backgroundColor: themeColors.grayLight,
          }}
        >
          <H3>{t('splitRosterPage.generalInfo')}</H3>
          <Box
            sx={{
              gap: { xs: 0, md: '14px' },
              mt: 1,
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box sx={{ width: { xs: '100%', md: '280px' } }}>
              <FormikInputString formik={formik} field="name" label={t('eventsPage.name')} isOptional />
            </Box>
            <Box sx={{ width: { xs: '100%', md: '280px' } }}>
              <FormikInputSelect
                formik={formik}
                field="yardFK"
                label={t('splitRosterPage.yardAssignment')}
                options={config.options.yards}
                disableClearable
              />
            </Box>
          </Box>
          <Box
            sx={{
              gap: { xs: 0, md: '14px' },
              mt: 1,
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box sx={{ width: { xs: '100%', md: '280px' } }}>
              <FormikCalendar formik={formik} field="startDate" label={t('splitRosterPage.startDay')} />
            </Box>
            <Box sx={{ width: { xs: '100%', md: '280px' } }}>
              <FormikInputSelect
                formik={formik}
                field="startTime"
                label={t('splitRosterPage.reportTime')}
                options={config.times.map((value: string) => ({
                  key: value,
                  value,
                }))}
                disableClearable
              />{' '}
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            width: { md: '622px' },
            padding: '24px 24px 1px 24px',
            margin: '0 auto 24px',
            borderRadius: '4px',
            backgroundColor: themeColors.grayLight,
          }}
        >
          <H3>{t('splitRosterPage.rosterOwner')}</H3>
          <Box
            sx={{
              gap: { xs: 0, md: '14px' },
              mt: 1,
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box sx={{ width: { xs: '100%' } }}>
              <FormikInputSelect
                formik={formik}
                field="newOwner"
                label={t('splitRosterPage.selectNewOwner')}
                options={optionsSelect}
                disableClearable
              />
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            width: { md: '622px' },
            padding: '24px 24px 1px 24px',
            margin: '0 auto 24px',
            borderRadius: '4px',
            backgroundColor: themeColors.grayLight,
          }}
        >
          <H3>{t('splitRosterPage.alternateContact')}</H3>
          <Box
            sx={{
              gap: { xs: 0, md: '14px' },
              mt: 1,
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box sx={{ width: { xs: '100%' } }}>
              <FormikInputString formik={formik} field="contactFullName" label={t('splitRosterPage.fullName')} isOptional />
            </Box>
          </Box>
          <Box
            sx={{
              gap: { xs: 0, md: '14px' },
              mt: 1,
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box sx={{ width: { xs: '100%', md: '280px' } }}>
              <FormikInputPhone formik={formik} field="contactPhone" label={t('splitRosterPage.phoneNumber')} isOptional />
            </Box>
            <Box sx={{ width: { xs: '100%', md: '280px' } }}>
              <FormikInputString formik={formik} field="contactEmail" label={t('splitRosterPage.emailAddress')} isOptional />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RosterDetailsStep;
