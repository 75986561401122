import { useState } from 'react';
import { GridPaginationModel } from '@mui/x-data-grid';

import { toNumber } from 'src/utils';
import { useQueryParam } from './useQueryParam';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

interface UsePaginationOptions {
  defaultPage?: number;
  defaultPageSize?: number;
  useQueryParams?: boolean;
}
export const usePagination = ({ defaultPage = DEFAULT_PAGE, defaultPageSize = DEFAULT_PAGE_SIZE, useQueryParams = false }: UsePaginationOptions) => {
  const [page, setPage] = useQueryParam<number>('page', defaultPage + 1, toNumber);
  const [pageSize, setPageSize] = useQueryParam<number>('perPage', defaultPageSize, toNumber);

  const [pagination, setPagination] = useState<GridPaginationModel>({
    page: page - 1,
    pageSize,
  });

  const onChangePagination = (model: GridPaginationModel) => {
    setPagination(model);
    if (useQueryParams) {
      setPage(model.page + 1);
      setPageSize(model.pageSize);
    }
  };

  return {
    pagination,
    onChangePagination,
  };
};
