const ROUTES = {
  HEALTH: '/utils/health',

  // CONFIG: '/config',  TODO: deprecated remove
  OPTIONS: '/config/options',
  TRANSLATES: '/config/translates/{language}',

  ROLES: '/roles',
  ROLES_PERMISSIONS: '/roles/permissions',

  AUTH_LOGIN: '/auth/login',
  AUTH_LOGOUT: '/auth/logout',
  VERIFY_CODE: '/auth/verify/{verificationCode}',
  AUTH_GET_CLEARME: '/auth/clearme',
  AUTH_CLEARME_VERIFY: '/auth/clearme/verify',

  USER: '/users/me',
  USER_UUID: '/users/{uuid}',
  USER_PERMISSIONS: '/users/my-permissions',
  USER_SET_CURRENT_ROLE: '/users/role/{role}',
  USER_INVITES: '/users/{uuid}/incidents?job=WITH_INVITATION',
  USER_REGISTER: '/auth/register',
  USER_RESEND: '/auth/resend',
  USER_EDIT: '/users/{uuid}',
  ACTIVATE: '/users/{uuid}/activate',
  BLOCK: '/users/{uuid}/block',
  USERS: '/users',
  USERS_FAVORITES: '/users?status=1&favorites={favorites}',
  USERS_UPLOAD: '/users/upload/{uuid}',
  USERS_DELETE_UPLOAD: '/users/upload/{id}',
  USERS_PROFILE: '/users/profile/',
  USERS_FAVORITE: '/users/favorites',
  NOTIFY_USERS: '/users/linemen/notify',
  LOAD_LINEMEN: '/users/workers/{incidentId}',
  LOAD_LINEMEN_BY_LOCATION: '/users/workers/{incidentId}?yardId={yardId}&favorites={favorites}',
  RESET_USER_PASSWORD: '/auth/reset',
  VERIFY_PASSWORD_TOKEN: '/auth/reset/{resetPasswordToken}',
  USER_SETTINGS: 'users/settings',
  USER_UPDATE_PASSWORD: 'auth/change',
  USER_VERIFY_UNION: '/users/{uuid}/union-classification',
  USER_ROLES: '/users/{uuid}/roles',
  USER_STATUS: '/users/{uuid}/status',
  SET_DEDICATED: '/users/{uuid}/dedicated',

  INCIDENTS: '/incidents?{query}',
  FILTERED_INCIDENTS: '/users/{uuid}/incidents?job={filter}',
  INCIDENT: '/incidents/{id}',
  INCIDENT_WITH_FILTERS: '/incidents/{id}?{query}',
  INCIDENT_INVITATION_STATUS: '/incidents/{id}/invitations',

  ROSTERS: '/rosters',
  ROSTER: '/rosters/{id}',
  ROSTER_WITH_VERSION: '/rosters/{id}?version={version}&view=myCrew,myEqpt',
  ROSTER_AVAILABLE_VEHICLES: '/vehicles',
  ROSTER_EQUIPMENTS: '/rosters/{id}/equipments',
  ROSTER_ASSIGNMENTS: '/rosters/{id}/assignments',
  ROSTER_EQUIPMENT_SET_OPTIONS: '/rosters/{id}/equipments/{uuid}',
  ROSTER_LINEMAN_SET_OPTIONS: '/rosters/{id}/lineman/{uuid}', // TODO: old code. remove with connected implementation
  ROSTER_SET_STATUS: '/rosters/{id}/status',
  ROSTER_TIMESHEET: '/rosters/{id}/timesheet',
  ROSTER_TRANSFER: '/rosters/{id}/transfer',
  ROSTER_REVERT: '/rosters/{id}/revert',
  ROSTER_SPLIT: '/rosters/{id}/split',

  VEHICLES: '/vehicles',
  VEHICLE: '/vehicles/{id}',

  // TODO: shoud be removed in the future
  UTILITY_CLASSIFICATIONS: '/utility/{id}/classifications',
};

export default ROUTES;
