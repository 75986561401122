import * as yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    phoneFormat(message?: string): this;
  }
}

export function addPhoneFormatMethod(): void {
  yup.addMethod<yup.StringSchema>(yup.string, 'phoneFormat', function (message = 'Invalid format. Use +1 (XXX) XXX-XXXX.') {
    return this.test('phone-format', message, function (value: string | undefined): boolean | yup.ValidationError {
      const { path, createError } = this;
      const phoneRegex = /^\+1 \d{10}$/;

      return value === undefined || phoneRegex.test(value) || createError({ path, message });
    });
  });
}

export const registerValidationMethods = () => {
  addPhoneFormatMethod();
};
