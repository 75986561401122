import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';

import { SagaAction } from '../../types';
import { setDedicated } from '../actions';

type UserPayloadType = {
  uuid: string;
};

function* setDedicatedSaga({ payload: { uuid } }: SagaAction<UserPayloadType>): SagaIterator {
  try {
    yield put(setDedicated.pending());
    yield call(API.setDedicatedUser, uuid);
    yield put(setDedicated.success());
  } catch (e) {
    yield put(setDedicated.failure());
  }
}

export default setDedicatedSaga;
