import { ChangeEvent, useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { InputAdornment, TextFieldProps } from '@mui/material';

import { addMobilePrefix, maskPhone, MOBILE_PREFIX } from 'src/utils';

import Input from '../Input';

const MAX_CHARACTERS = 14;

type FormikInputPhoneProps = {
  formik: FormikProps<any>;
  field: string;
  label: string;
  subLabel?: string;
  disabled?: boolean;
  isOptional?: boolean;
  autoFocus?: boolean;
  errorHelper?: Record<string, string[]>;
} & TextFieldProps;

const FormikInputPhone = ({ formik, field, errorHelper, ...props }: FormikInputPhoneProps): JSX.Element => {
  const [maskedValue, setMaskedValue] = useState('');

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const maskedValue = maskPhone(value);
    setMaskedValue(maskedValue);
    formik.handleChange(field)(maskedValue ? addMobilePrefix(maskedValue.replace(/\D/g, '')) : '');
  };

  useEffect(() => {
    const value = formik.values[field];
    const maskedValue = maskPhone(value.replace(MOBILE_PREFIX, ''));

    setMaskedValue(maskedValue);
  }, []);

  return (
    <Input
      name={field}
      type="text"
      value={maskedValue}
      maxLength={MAX_CHARACTERS}
      error={(formik.touched[field] && Boolean(formik.errors[field])) || (errorHelper && !!errorHelper[field])}
      // @ts-ignore
      helperText={(formik.touched[field] && formik.errors[field]) || (errorHelper && errorHelper[field])}
      {...props}
      placeholder="(000) 000-0000"
      InputProps={{
        startAdornment: <InputAdornment position="start">{MOBILE_PREFIX}</InputAdornment>,
      }}
      onChange={onChangeValue}
    />
  );
};

export default FormikInputPhone;
