import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { isEmpty, Parser } from 'src/utils';

export const useQueryParam = <T,>(
  key: string,
  defaultValue: T,
  parser: Parser<T> = (value, defaultValue) => (value as T) ?? defaultValue,
): [T, (newValue: T | null) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<T>(() => {
    const param = searchParams.get(key);
    return param ? parser(param, defaultValue) : defaultValue;
  });

  const onChange = (newValue: T | null) => {
    const newSearchParams = new URLSearchParams(window.location.search);

    if (isEmpty(newValue)) {
      newSearchParams.delete(key);
    } else {
      newSearchParams.set(key, String(newValue));
    }

    setSearchParams(newSearchParams, { replace: true });
    setValue(parser(String(newValue), defaultValue));
  };

  return [value, onChange];
};
