import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Button as MuiButton,
  MenuItem,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { H1, H3, P1R, P1M, Input, Button, Icon, Calendar } from 'src/components';
import { rosterTimesheet } from 'src/redux/roster/actions';
import config from 'src/config';
import { themeColors } from 'src/theme';
import { RosterType } from 'src/types';
import { DataCSVType, generateCSV, formatIncidentId, toCamelCase, formatPhone } from 'src/utils';

type Props = {
  loading?: boolean;
  primary?: boolean;
  label?: string;
  template?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  roster?: RosterType;
  onClose: () => void;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '790px',
    maxWidth: '790px',
    border: `1px solid ${themeColors.grayDisabled}`,
    backgroundColor: themeColors.grayLight,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(1),
    backgroundColor: themeColors.white,
    borderTop: `1px solid ${themeColors.grayDisabled}`,
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    backgroundColor: themeColors.white,
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    // eslint-disable-next-line react/jsx-filename-extension, react/jsx-props-no-spreading
    <DialogTitle sx={{ m: 0, padding: '20px 40px 20px 40px', marginBottom: -2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon name="close" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const Timesheet: React.FC<Props> = ({ label, disabled, children, onClose, roster, ...props }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Technical Debt: should be migrate in style file
  const filterStyles = { color: themeColors.grayDark, padding: '0 5px' };
  const dashStyles = {
    display: 'inline-block',
    height: '50px',
    verticalAlign: 'bottom',
    lineHeight: 0,
    margin: '0 5px',
  };
  const headerStyles = { textAlign: 'left', padding: '16px 0 16px 0' };

  const defaults = {
    startDate: dayjs().startOf('day').unix(),
    startTime: '06:00 AM',
    endDate: dayjs().add(1, 'days').startOf('day').unix(),
    endTime: '05:00 AM',
  };
  const [period, setPeriod] = useState(defaults);
  const [timesheet, setTimesheets] = useState({});

  const classificationName = (classificationId: number): string =>
    config.classifications.find(
      (obj: { key: number; value: string }) => obj.key === classificationId,
      // @ts-ignore
    )?.value || '';

  const normalizeName = (name: any): string =>
    `${name}`
      .replace(/[^a-zA-Z0-9]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');

  const csvFileName = `Storm360_${normalizeName(roster?.incident?.name || formatIncidentId(roster?.incident?.id))}_${normalizeName(
    roster?.name || roster?.id,
  )}_${dayjs().format('MM-DD-YYYY')}.csv`;
  const csvData = [
    [
      'fullName',
      'startDate',
      'startTime',
      'endDate',
      'endTime',
      'classification',
      'crewId',
      'foreman',
      'assignFleet',
      'companyTag',
      'email',
      'phone',
      'state',
      'street',
      'city',
      'zipcode',
      'gender',
      'lodging',
    ].map((columnName) => t(`timesheetDialog.${columnName}`)),
    ...(roster?.linemen || []).map((lineman) => {
      const assignment = lineman?.assignments?.[0];
      const linemanTimesheet =
        // @ts-ignore
        lineman.uuid !== undefined ? timesheet[lineman.uuid] : undefined;
      // @ts-ignore
      const assignedVehicle = roster?.vehicles.find((vehicle) => vehicle.id === assignment?.vehicleId);

      return [
        `${lineman.firstName} ${lineman.lastName}`,

        dayjs((linemanTimesheet?.startDate || defaults.startDate) * 1000).format(config.formatDate),
        linemanTimesheet?.startTime || defaults.startTime,
        dayjs((linemanTimesheet?.endDate || defaults.endDate) * 1000).format(config.formatDate),
        linemanTimesheet?.endTime || defaults.endTime,

        // @ts-ignore
        classificationName(assignment.classificationId),
        assignment?.crew,
        t(`timesheetDialog.${assignment?.isLead ? 'yes' : 'no'}`),
        assignedVehicle?.type ? t(`vehicles.${assignedVehicle?.type}`) : '',
        assignedVehicle?.companyTag,

        lineman.email,
        formatPhone(lineman.phone),
        lineman.stateCode,
        lineman.street,
        lineman.city,
        lineman.zipcode,

        lineman.gender,
        assignment?.lodging,
      ];
    }),
  ];

  const handlePeriodChange = (name: string, value: string) => {
    setPeriod((prev) => ({ ...prev, [name]: value }));
  };

  const handleTimesheetsChange = (uuid: string, name: string, value: string) => {
    // @ts-ignore
    setTimesheets((prev) => ({
      ...prev,
      // @ts-ignore
      [uuid]: { ...defaults, ...prev[uuid], [name]: value },
    }));
  };

  const handleUpdateAll = () => {
    const newTimesheet = (roster?.linemen || []).reduce(
      (timesheet, lineman) => ({
        ...timesheet,
        [lineman.uuid as string]: period,
      }),
      {},
    );
    setTimesheets(newTimesheet);
  };

  const handleSaveTimesheet = () => {
    const report = csvData.slice(1).map((lineman) =>
      csvData[0].reduce(
        (acc, fieldName, index) => ({
          ...acc,
          [toCamelCase(fieldName)]: lineman[index],
        }),
        {},
      ),
    );
    dispatch(
      rosterTimesheet.init({
        rosterId: roster?.id || 0,
        data: { roster, timesheet: report },
      }),
    );
  };

  return (
    <Box>
      <BootstrapDialog onClose={onClose} aria-labelledby="customized-dialog-title" open>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          <H1 align="left" sx={{ mt: 3, ml: '5px' }}>
            {t('timesheetDialog.title')}
          </H1>
          <H3
            sx={{
              ml: '5px',
              mt: 3,
              mb: 2,
              display: 'inline-block',
            }}
          >
            {t('timesheetDialog.subTitle')}
          </H3>
          <Grid container>
            <Grid item xs={12} style={filterStyles}>
              <Calendar
                name="startDate"
                label={t('timesheetDialog.startDate')}
                // @ts-ignore
                value={period.startDate}
                // @ts-ignore
                onChange={(name: string, value: number) =>
                  handlePeriodChange(
                    name,
                    // @ts-ignore
                    dayjs(value * 1000)
                      .startOf('day')
                      .unix(),
                  )
                }
                fullWidth={false}
                containerSx={{ display: 'inline-block', maxWidth: '150px' }}
              />
              <P1R sx={dashStyles}> - </P1R>
              <Input
                select
                name="startTime"
                label={t('timesheetDialog.startTime')}
                value={period.startTime}
                onChange={(e) => handlePeriodChange('startTime', e.target.value)}
                sx={{ height: '48px' }}
                containerSx={{
                  display: 'inline-block',
                  maxWidth: '130px',
                  paddingRight: '20px',
                }}
              >
                {config.times.map((value: string) => (
                  <MenuItem key={value} value={value}>
                    <P1R>{value}</P1R>
                  </MenuItem>
                ))}
              </Input>
              <Calendar
                name="endDate"
                label={t('timesheetDialog.endDate')}
                // @ts-ignore
                value={period.endDate}
                // @ts-ignore
                onChange={(name: string, value: number) =>
                  handlePeriodChange(
                    name,
                    // @ts-ignore
                    dayjs(value * 1000)
                      .startOf('day')
                      .unix(),
                  )
                }
                fullWidth={false}
                containerSx={{ display: 'inline-block', maxWidth: '150px' }}
              />
              <P1R sx={dashStyles}> - </P1R>
              <Input
                select
                name="endTime"
                label={t('timesheetDialog.endTime')}
                value={period.endTime}
                onChange={(e) => handlePeriodChange('endTime', e.target.value)}
                sx={{ height: '48px' }}
                containerSx={{ display: 'inline-block', maxWidth: '110px' }}
              >
                {config.times.map((value: string) => (
                  <MenuItem key={value} value={value}>
                    <P1R>{value}</P1R>
                  </MenuItem>
                ))}
              </Input>
              <Box sx={{ float: 'right', paddingTop: '25px' }}>
                <Button onClick={handleUpdateAll} sx={{ textTransform: 'none' }}>
                  {t('timesheetDialog.apply')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </BootstrapDialogTitle>
        <DialogContent sx={{ padding: '10px 40px 20px 40px !important' }}>
          {/* Technical Debt: should be migrate in components */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: '135px' }} sx={headerStyles}>
                  <P1M>{t('timesheetDialog.fullName')}</P1M>
                </TableCell>
                <TableCell sx={headerStyles}>
                  <P1M>{t('timesheetDialog.startDate')}</P1M>
                </TableCell>
                <TableCell sx={headerStyles}>
                  <P1M>{t('timesheetDialog.startTime')}</P1M>
                </TableCell>
                <TableCell sx={headerStyles}>
                  <P1M>{t('timesheetDialog.endDate')}</P1M>
                </TableCell>
                <TableCell sx={headerStyles}>
                  <P1M>{t('timesheetDialog.endTime')}</P1M>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(roster?.linemen || [])
                .filter((lineman) => lineman.uuid)
                .map((lineman) => (
                  <TableRow key={lineman.uuid}>
                    <TableCell
                      style={{
                        height: '48px',
                        textAlign: 'left',
                        backgroundColor: themeColors.grayLight,
                        paddingLeft: '10px',
                        borderRight: `1px solid ${themeColors.grayMedium}`,
                        borderLeft: `1px solid ${themeColors.grayMedium}`,
                      }}
                    >
                      <P1R>{`${lineman.firstName} ${lineman.lastName}`}</P1R>
                    </TableCell>
                    <TableCell
                      sx={{
                        height: '48px',
                        p: 0,
                        borderRight: `1px solid ${themeColors.grayMedium}`,
                      }}
                    >
                      <Calendar
                        name="startDate"
                        fullWidth={false}
                        // @ts-ignore
                        value={
                          // @ts-ignore
                          timesheet[lineman.uuid] ? timesheet[lineman.uuid].startDate : defaults.startDate
                        }
                        // @ts-ignore
                        onChange={(name: string, value: number) =>
                          // @ts-ignore
                          handleTimesheetsChange(lineman.uuid, name, value)
                        }
                        containerSx={{
                          maxHeight: '50px',
                          border: 'none',
                          '& fieldset': { border: 'none' },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        height: '48px',
                        p: 0,
                        borderRight: `1px solid ${themeColors.grayMedium}`,
                      }}
                    >
                      <Input
                        select
                        name="startTime"
                        // @ts-ignore
                        value={timesheet[lineman.uuid] ? timesheet[lineman.uuid].startTime : defaults.startTime}
                        // @ts-ignore
                        onChange={(e) => handleTimesheetsChange(lineman.uuid, 'startTime', e.target.value)}
                        containerSx={{ maxHeight: '50px' }}
                        sx={{
                          marginLeft: '10px',
                          backgroundColor: 'transparent',
                          border: 'none',
                          '& fieldset': { border: 'none' },
                          '& .MuiInputBase-input': { pl: 0 },
                        }}
                      >
                        {config.times.map((value: string) => (
                          <MenuItem key={value} value={value}>
                            <P1R>{value}</P1R>
                          </MenuItem>
                        ))}
                      </Input>
                    </TableCell>
                    <TableCell
                      sx={{
                        height: '48px',
                        p: 0,
                        borderRight: `1px solid ${themeColors.grayMedium}`,
                      }}
                    >
                      <Calendar
                        name="endDate"
                        fullWidth={false}
                        // @ts-ignore
                        value={timesheet[lineman.uuid] ? timesheet[lineman.uuid].endDate : defaults.endDate}
                        // @ts-ignore
                        onChange={(name: string, value: number) => handleTimesheetsChange(lineman.uuid, name, value)}
                        containerSx={{
                          maxHeight: '50px',
                          border: 'none',
                          '& fieldset': { border: 'none' },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        height: '48px',
                        p: 0,
                        borderRight: `1px solid ${themeColors.grayMedium}`,
                      }}
                    >
                      <Input
                        select
                        name="endTime"
                        // @ts-ignore
                        value={timesheet[lineman.uuid] ? timesheet[lineman.uuid].endTime : defaults.endTime}
                        // @ts-ignore
                        onChange={(e) => handleTimesheetsChange(lineman.uuid, 'endTime', e.target.value)}
                        containerSx={{ maxHeight: '50px' }}
                        sx={{
                          marginLeft: '10px',
                          backgroundColor: 'transparent',
                          border: 'none',
                          '& fieldset': { border: 'none' },
                          '& .MuiInputBase-input': { pl: 0 },
                        }}
                      >
                        {config.times.map((value: string) => (
                          <MenuItem key={value} value={value}>
                            <P1R>{value}</P1R>
                          </MenuItem>
                        ))}
                      </Input>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item sm={6}>
              <MuiButton
                fullWidth
                variant="outlined"
                onClick={onClose}
                sx={{
                  height: '48px',
                  textTransform: 'none',
                  borderColor: themeColors.grayMedium,
                }}
              >
                {t('timesheetDialog.cancel')}
              </MuiButton>
            </Grid>
            <Grid item sm={6}>
              <Box onClick={handleSaveTimesheet}>
                <Button
                  // FIXME: trouble reproduce just on dev deploy
                  // component="a"
                  href={generateCSV(csvData as DataCSVType)}
                  // @ts-ignore
                  download={csvFileName}
                  fullWidth
                  sx={{ textTransform: 'none' }}
                >
                  {t('timesheetDialog.save')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
};

export default Timesheet;
