import React from 'react';
import { FormikProps } from 'formik';
import Input from '../Input';

const MAX_CHARACTERS = 64;

type FormikInputNumberProps = {
  formik: FormikProps<any>;
  field: string;
  label: string;
  disabled?: boolean;
  errorHelper?: Record<string, string[]>;
};
const FormikInputNumber: React.FC<FormikInputNumberProps> = ({ formik, field, errorHelper, ...props }): JSX.Element => (
  <Input
    name={field}
    type="number"
    value={formik.values[field]?.toString()}
    onChange={formik.handleChange}
    maxLength={MAX_CHARACTERS}
    // @ts-ignore
    error={(formik.touched[field] && Boolean(formik.errors[field])) || (errorHelper && !!errorHelper[field])}
    // @ts-ignore
    helperText={(formik.touched[field] && formik.errors[field]) || (errorHelper && errorHelper[field])}
    {...props}
  />
);

export default FormikInputNumber;
