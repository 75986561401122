import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { themeColors } from 'src/theme';
import { Loader, P3, UserProfile } from 'src/components';
import { loadUser } from 'src/redux/user/actions';
import { selectIsUserLoading, selectSaveProfileSuccess, selectUser } from 'src/redux/user/selectors';
import { UserStatus } from 'src/shared/enums/userStatus.enum';

import { DashboardWrapper } from '../wrappers';

export default function MyProfile() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const saveProfileSuccess = useSelector(selectSaveProfileSuccess);
  const isUserLoading = useSelector(selectIsUserLoading);

  useEffect(() => {
    dispatch(loadUser.init());
  }, []);

  const approvalLabel = (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', sm: 'left' } }}>
      <Box
        sx={{
          width: '120px',
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: themeColors.lightYellow,
          borderRadius: '4px',
        }}
      >
        <P3>{t('common.waitingForApproval')}</P3>
      </Box>
    </Box>
  );

  return (
    <DashboardWrapper breadcrumbs={[['My Profile']]} navigationLabel={user.status === UserStatus.DEACTIVATED ? approvalLabel : null}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // height: '100vh',
          overflow: 'auto',
        }}
      >
        {isUserLoading && !saveProfileSuccess ? <Loader /> : <UserProfile currUser={user} user={user} />}
      </Box>
    </DashboardWrapper>
  );
}
