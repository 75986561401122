import { ProfileType, UserType, UserSettings } from 'src/types';
import { getUrl } from 'src/utils';
import { UploadFileType, UserFileType } from 'src/types/user';
import { UnionInfoStatus } from 'src/shared/enums/unionInfoStatus.enum';
import routes from '../routes';
import axios from '../axios';

const user = {
  createUser: async (user: Partial<UserType>): Promise<Partial<UserType>> => axios.post(routes.USER_REGISTER, user),
  resend: (uuid: string): Promise<Partial<UserType>> => axios.post(routes.USER_RESEND, { uuid }),
  patchUser: (data: Partial<UserType>): Promise<Partial<UserType>> =>
    axios.patch(routes.USERS, {
      ...data,
    }),
  editUser: (data: Partial<UserType>): Promise<Partial<UserType>> => {
    const { uuid, ...u } = data;
    return axios.patch(getUrl(routes.USER_EDIT, { uuid }), u);
  },
  verifyCode: (verificationCode: string): Promise<Partial<UserType>> => axios.get(getUrl(routes.VERIFY_CODE, { verificationCode })),
  getUser: (): Promise<Partial<UserType>> => axios.get(routes.USER),
  getUserUuid: (uuid: string): Promise<Partial<UserType>> => axios.get(getUrl(routes.USER_UUID, { uuid })),
  getUserPermissions: (): Promise<Partial<UserType>> => axios.get(routes.USER_PERMISSIONS),
  setUserCurrentRole: (role: string): Promise<Partial<UserType>> => axios.patch(getUrl(routes.USER_SET_CURRENT_ROLE, { role })),
  activateUser: (uuid: string): Promise<Partial<UserType>> => axios.patch(getUrl(routes.ACTIVATE, { uuid })),
  blockUser: (uuid: string): Promise<Partial<UserType>> => axios.patch(getUrl(routes.BLOCK, { uuid })),
  getUserInvites: (uuid: string): Promise<Partial<UserType>> => axios.get(getUrl(routes.USER_INVITES, { uuid })),

  setUserUpload: ({ cancelToken, userUuid, ...data }: Partial<UploadFileType>): Promise<Partial<UserFileType>> =>
    axios.post(getUrl(routes.USERS_UPLOAD, { uuid: userUuid }), data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      cancelToken,
    }),
  deleteUserUpload: ({ id }: { id: number }): Promise<Partial<UserFileType>> => axios.delete(getUrl(routes.USERS_DELETE_UPLOAD, { id })),

  resetUserPassword: ({ email }: { email: string }): Promise<Partial<UserFileType>> => axios.post(routes.RESET_USER_PASSWORD, { email }),
  verifyPasswordToken: ({ resetPasswordToken }: { resetPasswordToken: string }): Promise<any> =>
    axios.get(getUrl(routes.VERIFY_PASSWORD_TOKEN, { resetPasswordToken })),
  changeUserPassword: ({ password, resetPasswordToken }: { password: string; resetPasswordToken: string }): Promise<Partial<UserFileType>> =>
    axios.patch(routes.RESET_USER_PASSWORD, { password, resetPasswordToken }),

  saveProfile: async (data: Partial<ProfileType>): Promise<Partial<ProfileType>> =>
    axios.patch(routes.USERS_PROFILE, {
      ...data,
      classificationIds: data.classifications || data.classificationIds,
    }),
  loadProfile: async (): Promise<Partial<ProfileType>> => axios.get(routes.USERS),
  updateUserSettings: async (data: Partial<UserSettings>): Promise<Partial<UserSettings>> => axios.post(routes.USER_SETTINGS, data),
  updateUserPassword: ({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }): Promise<Partial<UserType>> =>
    axios.patch(routes.USER_UPDATE_PASSWORD, { oldPassword, newPassword }),
  favoriteUser: ({ favoriteUuid, action }: { favoriteUuid: string; action: string }): Promise<Partial<UserType>> =>
    axios.patch(routes.USERS_FAVORITE, { favoriteUuid, action }),
  verifyUnionInfo: ({
    uuid,
    verifyInfo,
  }: {
    uuid: string;
    verifyInfo: { unionInfoStatus: UnionInfoStatus; unionRejectedComment?: string };
  }): Promise<Partial<UserType>> => axios.patch(getUrl(routes.USER_VERIFY_UNION, { uuid }), verifyInfo),
  updateUserRoles: ({ uuid, roles }: { uuid: string; roles: string[] }): Promise<Partial<UserType>> =>
    axios.patch(getUrl(routes.USER_ROLES, { uuid }), roles),
  setUserStatus: (uuid: string, status: number): Promise<Partial<UserType>> => axios.patch(getUrl(routes.USER_STATUS, { uuid }), { status }),
  setDedicatedUser: (uuid: string): Promise<Partial<UserType>> => axios.patch(getUrl(routes.SET_DEDICATED, { uuid })),
};

export default user;
