import React from 'react';
import { Box, TextField, TextFieldProps, ThemeProvider, createTheme } from '@mui/material';
import { theme, themeColors } from 'src/theme';
import { P2R, P3 } from '../Typography';

type Props = {
  default?: boolean;
  error?: boolean;
  label?: string;
  subLabel?: string;
  value?: unknown;
  disabled?: boolean;
  isOptional?: boolean;
  sx?: object;
  fullWidth?: boolean;
  containerSx?: object;
  maxLength?: number;
} & TextFieldProps;

const Input: React.FC<Props> = ({
  error,
  label,
  subLabel,
  disabled,
  value,
  isOptional,
  sx = {},
  fullWidth = true,
  containerSx = {},
  maxLength,
  ...props
}): JSX.Element => (
  <ThemeProvider theme={createTheme(theme)}>
    <Box sx={{ pb: 3, ...containerSx }}>
      <Box sx={{ pb: '6px', display: 'flex', flexDirection: 'column' }}>
        <P2R sx={{ color: error ? themeColors.red : themeColors.black }}>
          {label}
          {isOptional ? <P2R sx={{ color: themeColors.grayDark }}> (Optional)</P2R> : null}
        </P2R>
        <P3 sx={{ lineHeight: 1 }}>{subLabel}</P3>
      </Box>
      <TextField
        inputProps={{ maxLength }}
        sx={{
          '& .MuiInputBase-root': { minHeight: 48, backgroundColor: themeColors.white },
          '& .MuiFormHelperText-root': { marginLeft: '0px' },
          '& .MuiInputBase-root.Mui-disabled': {
            '& > fieldset': {
              borderColor: error ? themeColors.red : null,
            },
          },
          ...sx,
        }}
        error={error}
        helperText={error && <P3 sx={{ color: themeColors.red, whiteSpace: 'pre-line' }}>{props?.helperText}</P3>}
        autoComplete="off"
        placeholder={props?.placeholder || label}
        disabled={disabled}
        value={value}
        fullWidth={fullWidth}
        size="medium"
        {...props}
      />
    </Box>
  </ThemeProvider>
);

export default Input;
